import axios from "./request";
import config from '@/config/index'
let {POWER_CABLE_BASE} = config;

/**
 * 获取消息列表
 * @param {*} params 
 * @returns 
 */
export const getMessageList = (params) => {
  return axios({
    url: '/power-cable-base/msg/page',
    method: 'get',
    params
  })
}

/**
 * 消息配置列表
 * @returns 
 */
export  function getMessageConfigList() {
  return axios({
    url: `power-cable-base/msg/config/all`,
    method: 'get',
  })
}

// 查看消息
export  function getMessage(id) {
  return axios({
    url: `${POWER_CABLE_BASE}/msg/read/${id}`,
    method: 'get',
  })
}

//基础消息设置
export function getSettingAcceptMessage(){
  return axios({
    url:`${config.baseUrl}/acceptMessage/list`,
    method:'get'
  })
}

//添加、编辑角色消息设置
export function saveAcceptMessage(data){
  return axios({
    url:`${config.baseUrl}/acceptMessage/save`,
    method:'post',
    data
  })
}
//删除、批量删除
export function delMessageInfo(string){
  return axios({
    url:`${config.POWER_CABLE_BASE}/msg/del?ids=${string}`,
    method:'delete',
  })
}

//批量已读
export function batchReadInfo(params){
  return axios({
    url:`${config.POWER_CABLE_BASE}/msg/readBatch`,
    method:'get',
    params
  })
}