import { EventBus } from '@/utils/vueBus';
import config from '@/config/index'
export const Websocket = {
    data() {
        return {
            reconnectInterval: 5000,
            baseUrl: process.env.VUE_APP_SOCKET_API,
            type: true,
            classNameObj: {},
            websock: null,
            heartbeatInterval: null
        }
    },
    computed: {
        ExternalLink() {
            return this.$store.state.app.ExternalLink;
        },
        token() {
            return this.$store.getters['user/token'];
        },
    },
    mounted() {
        if (this.token) {
            this.initWebSocket();
        }
        EventBus.$on('mySocketEvent', () => {
            this.initWebSocket();
        })
        EventBus.$on('stopMySocketEvent', () => {
            this.websocketOnclose();
        })
    },
    destroyed: function () {
        // 离开页面生命周期函数
        EventBus.$off('stopMySocketEvent');
        EventBus.$off('mySocketEvent');
        this.websocketOnclose();
    },
    methods: {
        initWebSocket() {
            let token = this.token
            const headers = [
                'token', //请求头的名称
                token // 请求头的值
            ];
            let id = new Date().getTime();
            this.websock = new WebSocket(`${this.baseUrl}${config.POWER_CABLE_BASE}/websocket/${id}`, headers);
            this.websock.onopen = this.websocketOnopen;
            this.websock.onerror = this.websocketOnerror;
            this.websock.onmessage = this.websocketOnmessage;
            this.websock.onclose = this.websocketOnclose;
        },
        websocketOnopen() {
            console.log("WebSocket连接成功");
            this.startHeartbeat();
        },
        websocketOnmessage(e) {
            console.log(e)
            let data = JSON.parse(e.data)
            this.reset()
            if (e.data == "pong") { } else {
                let data = JSON.parse(e.data)
                this.showTip(data);
            }
        },
        websocketOnerror(e) {
            console.log(e, "WebSocket连接发生错误");
            this.reconnect();
        },
        websocketOnclose(e) {
            console.log("WebSocket连接已断开")
            this.websock.close();
            this.clearHeartbeat();
        },
        reset() {
            this.clearHeartbeat();
            this.startHeartbeat();
        },
        startHeartbeat() {
            // 设置心跳间隔，这里以30秒为例
            this.heartbeatInterval = setInterval(() => {
                if (this.websock && this.websock.readyState === WebSocket.OPEN) {
                    // 发送心跳消息
                    this.websock.send('heartbeat');
                }
            }, 60000);
        },
        clearHeartbeat() {
            if (this.heartbeatInterval) {
                clearInterval(this.heartbeatInterval);
            }
        },
        reconnect() {
            //没连接上会一直重连，设置延迟避免请求过多
            setTimeout(() => {
                console.info("尝试重连...");
                this.initWebSocket();
            }, this.reconnectInterval);
        },
        showTip(data) {
            const h = this.$createElement;
            const contents = [];
            const msg = h(
                "div",
                {
                    style: {
                        height: "200px",
                        width: "300px",
                    },
                },
                [
                    h(
                        "div",
                        {
                            style: {
                                display: "flex",
                                "align-items": "center",
                                width: "300px",
                                "box-sizing": "border-box",
                                height: "38px !important",
                                padding: "16px 20px",
                                background: "#2489F2",
                                color: "#FFFFFF",
                                height: "38px",
                                "font-size": "16px",
                                "font-weight": "500",
                            },
                        },
                        "消息提醒"
                    ),
                    h(
                        "div",
                        {
                            style: {
                                height: "162px",
                                "box-sizing": "border-box",
                                padding: "20px",
                            },
                        },
                        [
                            h(
                                "div",
                                {
                                    style: {
                                        "font-weight": "500",
                                        "font-size": "14px",
                                        color: "#333333",
                                        "margin-bottom": "30px",
                                        'word-wrap': 'break-word',   //自动换行
                                        "word-break": "break-all",    //自动换行（兼容字母）
                                        overflow: 'hidden',       //超出隐藏
                                        "text-overflow": 'ellipsis',   //溢出显示省略号
                                        display: '-webkit-box',
                                        "-webkit-line-clamp": '3',  //显示3行
                                        "-webkit-box-orient": 'vertical'
                                    },
                                },
                                data.message
                            ),
                            h(
                                "div",
                                {
                                    style: {
                                        display: "flex",
                                        "justify-content": "center",
                                    },
                                },
                                [
                                    h(
                                        "div",
                                        {
                                            class: {
                                                "el-progress-plus": true,
                                            },
                                            style: {
                                                cursor: "pointer",
                                            },
                                            on: {
                                                //为按钮绑定点击事件
                                                click: () => {
                                                    this.readInfoNotice(notifyInstance);
                                                },
                                                percentage: 5,
                                            },
                                        },
                                        "忽略5s"
                                    ),
                                    this.type
                                        ? h(
                                            "div",
                                            {
                                                style: {
                                                    cursor: "pointer",
                                                    "margin-left": "60px",
                                                },
                                                on: {
                                                    //为按钮绑定点击事件
                                                    click: () => {
                                                        this.handleJump(data.identification, data.path);
                                                    },
                                                },
                                            },
                                            "去处理"
                                        )
                                        : "",
                                ]
                            ),
                        ]
                    ),
                ]
            );
            contents.push(msg);
            let classID = 1;
            let className = "el-notification-plus" + classID;
            // 判断是否已存在该通知元素，以及最多限制生成10000个通知
            while (document.getElementsByClassName(className)[0]) {
                if (classID > 10000) {
                    // 无法生成元素
                    console.log("无法生成元素");
                    break;
                } else {
                    // 继续累加
                    classID++;
                    className = "el-notification-plus" + classID;
                }
            }

            // 实例化通知
            const notifyInstance = this.$notify({
                position: "bottom-right",
                customClass: `${className} notice`,
                message: h("div", {}, contents),
                duration: 5000,
            });
            console.log(notifyInstance);
            // 启动倒计时
            let timer = this.countDown(className, notifyInstance, 5); // 这里做了修改，默认传100，因为再倒计时方法获取不到定义的percentage为100的属性

            // 获取 Notification 的DOM元素
            const ElNotificationPlus = document.getElementsByClassName(className)[0];

            // 为 Notification 元素 定义鼠标进入方法，暂停倒计时
            ElNotificationPlus.onmouseenter = () => {
                clearInterval(timer);
            };

            // 为 Notification 元素 定义鼠标移出方法，继续倒计时
            ElNotificationPlus.onmouseleave = () => {
                clearInterval(timer);
                timer = this.countDown(
                    className,
                    notifyInstance,
                    this.classNameObj[className]
                );
            };
        },
        // 按钮事件
        readInfoNotice(notifyInstance) {
            // 手动关闭通知
            setTimeout(() => {
                notifyInstance.close();
            }, 50);
            // 标记为已读  调用的接口做一些处理
            // updReadNotice(notice_id).then((response) => {
            //   if (response.code && response.code === 200) {
            //     this.msgSuccess(response.msg);
            //   } else {
            //     this.msgError("操作失败");
            //   }
            // });
        },
        /**
         * 倒计时
         */
        countDown(className, notifyInstance, countdown) {
            //增加了当前属性的传参countdown
            const timer = setInterval(() => {
                try {
                    if (notifyInstance) {
                        const ElNotificationPlus =
                            document.getElementsByClassName(className)[0];
                        // console.log('ElNotificationPlus =>', ElNotificationPlus)
                        const ElProgressPlus =
                            ElNotificationPlus.getElementsByClassName("el-progress-plus")[0];
                        // console.log("ElProgressPlus =>", ElProgressPlus);

                        let percentage =
                            ElProgressPlus.getAttribute("percentage") || countdown; // 获取不到的时候就取传参的值
                        // console.log(ElProgressPlus ,'222222222222222')
                        this.classNameObj[className] = percentage;
                        if (percentage > 0) {
                            percentage = percentage - 1;
                            ElProgressPlus.setAttribute("percentage", percentage);
                            ElProgressPlus.innerHTML = "忽略" + percentage + "s";
                        } else {
                            // 清除定时器
                            clearInterval(timer);
                            delete this.classNameObj[className]; // 为0 或关闭时则清空该字段
                            // 手动关闭通知
                            setTimeout(() => {
                                notifyInstance.close();
                            }, 50);
                        }
                    } else {
                        // 清除定时器
                        clearInterval(timer);
                        delete this.classNameObj[className]; // 为0 或关闭时则清空该字段
                    }
                } catch (error) {
                    // 清除定时器
                    clearInterval(timer);
                    delete this.classNameObj[className]; // 为0 或关闭时则清空该字段
                }
            }, 1000);

            return timer;
        },
        handleJump(type, path) {
            if (type == 1) {
                window.open(`${this.ExternalLink + path}&token=${getToken()}`);
            } else {
                window.open(path);
            }
        }
    }
}